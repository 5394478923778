import { mapGetters, mapMutations, mapState } from 'vuex';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnTable from '../../shared/components/KnTable.vue';
import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import {
  fetchSuppliers,
  delSupplier,
  // deleteSupplier,
  reactivateSupplier,
} from '../helpers/suppliersOptions';
import { getFullName } from '../../shared/helpers/dataUtils';
import {
  canAdd,
  canDelete,
  canChange,
  insufficientPermissionsMessage,
} from '../../shared/helpers/permissionsUtils';
export default {
  components: { KnLocalAlert, KnTable },
  mixins: [paginationMixin],
  data() {
    return {
      groups: [],
      suppliers: [],
      headers: [
        { text: 'Razón social', value: 'razon_social', class: 'purple--text' },
        { text: 'Contacto', value: 'contacto', class: 'purple--text' },
        { text: 'Categoría', value: 'categoria', class: 'purple--text' },
        { text: 'Subcategoría', value: 'sub_categoria', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      // search: null,
      showInactive: false,
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'search']),
    ...mapGetters(['isBasicEmployee', 'isSuperAdmin', 'userId']),
    buttomActionText() {
      return this.showInactive
        ? 'Ver Proveedores Activos'
        : 'Ver Proveedores Inactivos';
    },
    tableTitle() {
      return this.showInactive ? 'Proveedores Inactivos' : 'Proveedores';
    },
  },
  watch: {
    showInactive: {
      async handler() {
        await this.getPaginatedSuppliers(1);
      },
    },
    institutionId: {
      async handler() {
        this.setIsLogin(false);
        await this.getPaginatedSuppliers(1);
      },
    },
    search: {
      async handler() {
        await this.getPaginatedSuppliers(1);
      },
    },
  },
  async created() {
    this.setIsLogin(false);
    await this.getPaginatedSuppliers(1);
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    canAdd: canAdd,
    async getPaginatedSuppliers(page = 1) {
      this.items = [];
      this.setPaginationPage(page);
      if (!this.loading) {
        this.loading = true;
        const { ok, data, message, count } = await fetchSuppliers({
          institutionId: this.institutionId,
          systemStatus: !this.showInactive,
          supplierName: this.search,
          limit: this.pagination.limit,
          offset: this.pagination.offset,
        });
        if (ok) {
          this.setPaginationCount(count);
          const suppliers = data;
          for (const supplier of suppliers) {
            let tradeName = '';
            let fullName = 'Sin contacto';
            if (supplier.nombre_proveedor) {
              tradeName = supplier.nombre_proveedor;
            } else if (
              supplier.datos_fiscales &&
              supplier.datos_fiscales.length > 0
            ) {
              tradeName = supplier.datos_fiscales[0].nombre_o_razon_social;
            } else {
              const { first_name: fn, last_name: ln } = supplier.usuario;
              if (fn !== '' || ln !== '') {
                tradeName = `${fn} ${ln}`;
              } else {
                tradeName = 'Sin nombre';
              }
            }
            if (supplier.datos_personales !== null) {
              fullName = getFullName(supplier.datos_personales);
            }

            //En esta sección se extraen las categorias y subCategorias
            const categories = supplier.categorias.map((cat) => cat.dato);
            const subcategories = supplier.sub_categorias.map(
              (subcat) => subcat.dato
            );

            const item = {
              razon_social: tradeName,
              contacto: fullName,
              categoria: categories.join(', '),
              sub_categoria: subcategories.join(', '),
              id: supplier.id,
              ...supplier,
            };
            this.items.push(item);
          }
        } else {
          console.log('No se pudieron obtener proveedores', message);
        }
        this.loading = false;
      }
    },
    async action2(supplierId) {
      if (this.showInactive) {
        await this.enableSupplier(supplierId);
      } else {
        await this.disableSupplier(supplierId);
      }
    },
    /**
     * Desactiva un proveedor
     * @param {number} supplierId Id del proveedor a eliminar
     */
    async disableSupplier(supplierId) {
      try {
        if (canDelete('proveedor')) {
          const { ok } = await delSupplier(supplierId);
          // console.log('Respuesta data disable proveedor', data);
          if (ok) {
            await this.getPaginatedSuppliers(1);
          } else {
            // console.log('No se pudo desactivar el proveedor', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar proveedor', error);
      }
    },

    async enableSupplier(supplierId) {
      try {
        if (canChange('proveedor')) {
          const { ok } = await reactivateSupplier(supplierId);
          // console.log('Respuesta data enable proveedor', data);
          if (ok) {
            await this.getPaginatedSuppliers(1);
          } else {
            // console.log('No se pudo reactivar el proveedor', message);
          }
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar un proveedor', error);
      }
    },
  },
};
